.About .harfen-photo {
   margin-top: 25px;
   width:100%;
}

.About .description {
   margin-bottom: 25px;
}

@media (max-width:1199.96px){
   .About .harfen-photo{
   margin-left: 20%;
   width: 60%;
   }
}

@media (min-width: 1200px){
   .About .harfen-photo{
   width: 90%;
   margin-right: 10%;
   }
}