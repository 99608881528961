.Contact h2{
   font-family: 'Noto Sans', sans-serif;
   color: #555;
   font-size: 20px;
}

.Contact a{
   text-decoration: none;
   color: #555;
   width: 100%;
}

@media (max-width:430.96px){
   .Contact h2{
      font-size: 16px;
   }
}

