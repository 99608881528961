select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

body {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  color: #555;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
}




.App .sc-gzVnrw{
  display: none;
  background-color: #f8f8f8;
}

.App .mobile-navbar{
  display: none;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .lander {
  text-align: center;
  top:0;
  position: -webkit-sticky;
  position: sticky;
}

.App .lander h1 {
  font-family: "Noto Sans", sans-serif;
  color: #0f0f0f;
  font-weight: 700;
}

.App .lander p {
  color: #999;
}

.App .logo {
  margin-top: 15px;
  width: 100%;
  height: 80%;
}

.App .logo-mobile{
  height: 100%;
}

.App .nav {
  margin: 20px 0 0 0;
  font-size: 20px;
  font-family: "Noto Sans", sans-serif;
  color: #333;
}

.App .nav a{
  color: #999;
  text-decoration: none;
}

.App .nav a:hover {
  color: #333;
}

.App .nav ul{
  list-style: none;
  padding: 0 0 0 0;
}

.App .nav li{
  margin: 0 0 10px 0;
  font-family: 'Open Sans', sans-serif;
  font-size:20px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;

}


@media only screen and (min-width: 1200px) {
  .App .sc-gzVnrw{
    display: block;
    
  }
}

@media (max-width: 1199.96px) {
  .App .mobile-navbar {
    display: block;
  }
}


@media (min-width: 768px){
  .App .logo-mobile {
    height: 50px;
  }
}
@media (max-width: 1199.96px) and (min-width:768px){
  .App .page-header {
    margin: 80px 0 0 0;
  }
}
.NotFound {
   padding-top: 100px;
   text-align: center;
 }

 .NotFound h3 {
   font-family: "Noto Sans", sans-serif;
   font-weight: 700;
 }
.LoaderButton .spinning.glyphicon {
   margin-right: 7px;
   top: 2px;
   -webkit-animation: spin 1s infinite linear;
           animation: spin 1s infinite linear;
 }
 @-webkit-keyframes spin {
   from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
   to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
 }
 @keyframes spin {
   from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
   to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
 }
.Notes form {
   padding-bottom: 15px;
 }
 
 .Notes form textarea {
   height: 300px;
   font-size: 24px;
 }
.Contact h2{
   font-family: 'Noto Sans', sans-serif;
   color: #555;
   font-size: 20px;
}

.Contact a{
   text-decoration: none;
   color: #555;
   width: 100%;
}

@media (max-width:430.96px){
   .Contact h2{
      font-size: 16px;
   }
}


.About .harfen-photo {
   margin-top: 25px;
   width:100%;
}

.About .description {
   margin-bottom: 25px;
}

@media (max-width:1199.96px){
   .About .harfen-photo{
   margin-left: 20%;
   width: 60%;
   }
}

@media (min-width: 1200px){
   .About .harfen-photo{
   width: 90%;
   margin-right: 10%;
   }
}


.Project img{
   width:100%;
}


.Project .mobile-view{
   display:none;
}

.Project .desktop-view{
   display:none;
}

.Project .row{
   padding-top: 8px;
   padding-bottom: 8px;
}



/*mobile*/
@media (max-width:1024.96px){

   .Project .mobile-view{
      display: block;
   }
   .Project .pics{
      padding-top:8px;
      padding-bottom:8px;
   }
   .Project .row{
      padding: 0;
   }
}

/*potrait view edits, not sure if necessary. Need android/ipad
@media (min-width: 439px){
   .Project .rot90{
      padding-top:72px;
      padding-bottom:72px;
   }
}
@media (min-width: 640px){
   .Project .rot90{
      padding-top:102px;
      padding-bottom:102px;
   }
}
@media (min-width: 950px){
   .Project .rot90{
      padding-top: 128px;
      padding-bottom: 128px;
   }
}
*/

/*desktop*/
@media (min-width:1025px){
   .Project .desktop-view{
      display:block;
   }

   .Project img {
      max-height: 70vh;
      object-fit: contain;
   }

   .Project .rot90{
      padding-top: 0px;
      padding-bottom: 0px;
   }
   .Project .rot90{
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
   }
}
