

.Project img{
   width:100%;
}


.Project .mobile-view{
   display:none;
}

.Project .desktop-view{
   display:none;
}

.Project .row{
   padding-top: 8px;
   padding-bottom: 8px;
}



/*mobile*/
@media (max-width:1024.96px){

   .Project .mobile-view{
      display: block;
   }
   .Project .pics{
      padding-top:8px;
      padding-bottom:8px;
   }
   .Project .row{
      padding: 0;
   }
}

/*potrait view edits, not sure if necessary. Need android/ipad
@media (min-width: 439px){
   .Project .rot90{
      padding-top:72px;
      padding-bottom:72px;
   }
}
@media (min-width: 640px){
   .Project .rot90{
      padding-top:102px;
      padding-bottom:102px;
   }
}
@media (min-width: 950px){
   .Project .rot90{
      padding-top: 128px;
      padding-bottom: 128px;
   }
}
*/

/*desktop*/
@media (min-width:1025px){
   .Project .desktop-view{
      display:block;
   }

   .Project img {
      max-height: 70vh;
      object-fit: contain;
   }

   .Project .rot90{
      padding-top: 0px;
      padding-bottom: 0px;
   }
   .Project .rot90{
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
   }
}